// @flow strict
import React from 'react';

import Solid from './Solid';
import Curly from './Curly';
import Crown from './Crown';
import Allow from './Arrow';
import Dot from './Dot';

type Props = {
  shape?: 'solid' | 'curly' | 'crown' | 'arrow' | 'dot',
  height?: String,
};

const HorizontalLine = ({ shape, height }: Props) => {
  switch (shape.toLowerCase()) {
    case 'curly':
      return <Curly />;
    case 'arrow':
      return <Allow />;
    case 'crown':
      return <Crown />;
    case 'dot':
      return <Dot />;
    default:
      return <Solid height={height} />;
  }
};

HorizontalLine.defaultProps = {
  shape: 'solid',
  height: '10px',
};

export default HorizontalLine;
