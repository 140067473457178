// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("./../../../src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-instagram-js": () => import("./../../../src/pages/instagram.js" /* webpackChunkName: "component---src-pages-instagram-js" */),
  "component---src-pages-purchase-js": () => import("./../../../src/pages/purchase.js" /* webpackChunkName: "component---src-pages-purchase-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-puppy-list-page-js": () => import("./../../../src/templates/PuppyListPage.js" /* webpackChunkName: "component---src-templates-puppy-list-page-js" */),
  "component---src-templates-puppy-post-js": () => import("./../../../src/templates/PuppyPost.js" /* webpackChunkName: "component---src-templates-puppy-post-js" */)
}

