// @flow strict
import React from 'react';
import styled from '@emotion/styled';

type Props = {
  align?: 'left' | 'right' | 'center',
};

const Colophon = ({ align }: Props) => (
  <Root align={align}>&copy; Copyright Little Mei. All rights reserved.</Root>
);

Colophon.defaultProps = {
  align: 'center',
};

const Root = styled.div((props) => ({
  fontSize: '0.8rem',
  textAlign: props.align,
}));

export default Colophon;
