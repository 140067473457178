// @flow strict
import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';

import store from '../redux/store';
import { theme } from '../styles';

type Props = {
  element: React.Node,
};

const wrapRootElement = ({ element }: Props) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>{element}</ThemeProvider>
  </Provider>
);

export default wrapRootElement;
