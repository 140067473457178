import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

import SiteLogo from '../atoms/SiteLogo';
import BurgerButton from '../atoms/BurgerButton';
import HorizontalLine from '../atoms/HorizontalLine';
import NaviMenu from '../molecules/NaviMenu';
import { useSiteMetadata } from '../hooks';

const Toolbar = () => {
  const theme = useTheme();
  const { menu } = useSiteMetadata();

  return (
    <Root>
      <SiteLogoLayout>
        <Link to="/">
          <SiteLogo square />
        </Link>
      </SiteLogoLayout>
      <Margin />
      <NaviMenuLayout>
        <NaviMenu menu={menu} />
        <HorizontalLine shape="dot" />
      </NaviMenuLayout>
      <BurgerButtonLayout>
        <BurgerButton color={theme.colors.primary} />
      </BurgerButtonLayout>
    </Root>
  );
};

const Root = styled.div((props) => ({
  [`@media (min-width: ${props.theme.breakpoints.tablet + 1}px)`]: {
    display: 'flex',
  },
}));

const SiteLogoLayout = styled.div((props) => ({
  margin: '0.5rem auto',
  width: 74,
  [`@media (min-width: ${props.theme.breakpoints.tablet + 1}px)`]: {
    margin: '1rem',
    width: 148,
  },
}));

const BurgerButtonLayout = styled.div((props) => ({
  position: 'absolute',
  top: 24,
  right: '1rem',
  [`@media (min-width: ${props.theme.breakpoints.tablet + 1}px)`]: {
    display: 'none',
  },
}));

const Margin = styled.div((props) => ({
  display: 'none',
  [`@media (min-width: ${props.theme.breakpoints.tablet + 1}px)`]: {
    display: 'block',
    flexGrow: 1,
  },
}));

const NaviMenuLayout = styled.div((props) => ({
  display: 'none',
  [`@media (min-width: ${props.theme.breakpoints.tablet + 1}px)`]: {
    display: 'block',
    marginRight: '2rem',
    marginTop: '2rem',
  },
}));

export default Toolbar;
