// @flow strict
import React from 'react';
import styled from '@emotion/styled';

import Drawer from '../molecules/Drawer';
import SiteLogo from '../atoms/SiteLogo';
import HorizontalLine from '../atoms/HorizontalLine';
import NaviMenu from '../molecules/NaviMenu';

type Props = {
  menu: {
    label: string,
    path: string,
  }[],
};

const NarrowHeader = ({ menu }: Props) => (
  <Drawer>
    <DrawerInner>
      <SiteLogo />
      <NaviMenuLayout>
        <NaviMenu vertical menu={menu} />
      </NaviMenuLayout>
      <HorizontalLine shape="dot" />
    </DrawerInner>
  </Drawer>
);

const DrawerInner = styled.div({
  padding: '1.5rem',
});

const NaviMenuLayout = styled.div({
  margin: '1rem auto 1.5rem',
});

export default NarrowHeader;
