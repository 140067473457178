// @flow strict
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { toggleDrawer } from '../redux/action';

type Props = {
  children: React.Node,
  color?: string,
  width?: string,
};

const Drawer = ({ children, color, width }: Props) => {
  const dispatch = useDispatch();
  const showDrawer = useSelector((state) => state.showDrawer);

  return (
    <Background showDrawer={showDrawer} onClick={() => dispatch(toggleDrawer())}>
      <Panel color={color} width={width} showDrawer={showDrawer}>
        {children}
      </Panel>
    </Background>
  );
};

Drawer.defaultProps = {
  color: '#FFF',
  width: '280px',
};

const Background = styled.div((props) => ({
  backgroundColor: props.showDrawer ? 'rgba(0, 0, 0, 0.75)' : 'rgba(0, 0, 0, 0)',
  height: '100vh',
  left: 0,
  pointerEvents: props.showDrawer ? 'auto' : 'none',
  position: 'absolute',
  transition: 'background-color .3s ease-in-out',
  top: 0,
  width: '100vw',
  zIndex: 9999,
}));

const Panel = styled.div((props) => ({
  backgroundColor: props.color,
  height: '100vh',
  position: 'relative',
  transform: props.showDrawer ? 'translateX(0)' : 'translateX(-100%)',
  transition: 'transform .3s ease-in-out',
  width: props.width,
}));

export default Drawer;
