// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const query = graphql`
  query {
    file(relativePath: { eq: "bullet.png" }) {
      childImageSharp {
        fluid(maxWidth: 27) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Bullet = () => (
  <StaticQuery
    query={query}
    render={(data) => <GarberaBullet fluid={data.file.childImageSharp.fluid} alt="Bullet" />}
  />
);

Bullet.defaultProps = {
  square: false,
};

const GarberaBullet = styled(Img)({
  maxWidth: 27,
});

export default Bullet;
