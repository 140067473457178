// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import { toggleDrawer } from '../redux/action';

type Props = {
  color?: string,
};

const BurgerButton = ({ color }: Props) => {
  const dispatch = useDispatch();
  const showDrawer = useSelector((state) => state.showDrawer);

  return (
    <Root color={color} showDrawer={showDrawer} onClick={() => dispatch(toggleDrawer())}>
      <div />
      <div />
      <div />
    </Root>
  );
};

const Root = styled.button((props) => ({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  height: '1.8rem',
  justifyContent: 'space-between',
  padding: 0,
  width: '2.4rem',
  zIndex: 10000,
  '&:focus': {
    outline: 'none',
  },
  '& > div': {
    background: props.color,
    borderRadius: '10px',
    display: 'inline-block',
    height: '.25rem',
    position: 'relative',
    transformOrigin: '1px',
    transition: 'all .3s linear',
    width: '2.4rem',
  },
  '& > div:nth-of-type(1)': {
    transform: props.showDrawer ? 'rotate(45deg)' : 'rotate(0)',
  },
  '& > div:nth-of-type(2)': {
    opacity: props.showDrawer ? 0 : 1,
    transform: props.showDrawer ? 'translateX(20px)' : 'translateX(0)',
  },
  '& > div:nth-of-type(3)': {
    transform: props.showDrawer ? 'rotate(-45deg)' : 'rotate(0)',
  },
}));

BurgerButton.defaultProps = {
  color: '#000',
};

export default BurgerButton;
