// @flow strict
import React from 'react';
import styled from '@emotion/styled';

import NaviMenuItem from './NaviMenuItem';

type Props = {
  menu: {
    label: String,
    path: String,
  }[],
  vertical?: Boolean,
};

const NaviMenu = ({ menu, vertical }: Props) => (
  <List vertical={vertical}>
    {menu.map((item) => (
      <NaviMenuItem key={item.path} label={item.label} path={item.path} />
    ))}
  </List>
);

NaviMenu.defaultProps = {
  vertical: false,
};

const List = styled.div((props) => ({
  display: 'flex',
  flexDirection: props.vertical ? 'column' : 'row',
}));

export default NaviMenu;
