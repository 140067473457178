// @flow strict
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch } from 'react-redux';

import Toolbar from '../organisms/Toolbar';
import NaviMenuDrawer from '../organisms/NaviMenuDrawer';
import SiteFooter from '../organisms/SiteFooter';
import { useSiteMetadata } from '../hooks';
import { updateWindowDimension } from '../redux/action';
import { getWindowDimensions } from '../utils';

type Props = {
  children: React.Node,
};

const Layout = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { title, description, menu } = useSiteMetadata();

  useEffect(() => {
    const handleResize = () => {
      const dim = getWindowDimensions();
      dispatch(updateWindowDimension(dim.width, dim.height));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <html lang="ja" />
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
      </HelmetProvider>
      <Root>
        <Toolbar />
        {children}
        <SiteFooter />
        <NaviMenuDrawer menu={menu} />
      </Root>
    </>
  );
};

const Root = styled.div((props) => ({
  margin: '0 auto',
  maxWidth: props.theme.layout.maxWidth,
}));

export default Layout;
