// @flow strict
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from '@emotion/styled';

import Bullet from '../atoms/Bullet';

type Props = {
  label: String,
  path: String,
};

const NaviMenuItem = ({ label, path }: Props) => {
  return path.indexOf('https') === 0 ?
    (
      <Anchor href={path} rel="noreferrer" target="_blank">
        <ItemWrapper>
          <BulletLayout>
            <Bullet />
          </BulletLayout>
          <div>{label}</div>
        </ItemWrapper>
      </Anchor>
    ) : (
      <Link to={path}>
        <ItemWrapper>
          <BulletLayout>
            <Bullet />
          </BulletLayout>
          <div>{label}</div>
        </ItemWrapper>
      </Link>
    )
};

const ItemWrapper = styled.div({
  display: 'flex',
  padding: '0.75rem 0.5rem',
});

const BulletLayout = styled.div({
  margin: '4px 6px 0 0',
  width: 20,
});

const Link = styled(GatsbyLink)((props) => ({
  color: props.theme.colors.text,
}));

const Anchor = styled.a((props) => ({
  color: props.theme.colors.text,
}));

export default NaviMenuItem;
