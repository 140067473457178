// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

type Props = {
  square?: Boolean,
};

const query = graphql`
  query {
    square: file(relativePath: { eq: "SiteLogo/square.png" }) {
      childImageSharp {
        fluid(maxWidth: 148) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wide: file(relativePath: { eq: "SiteLogo/wide.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const SiteLogo = ({ square }: Props) => (
  <StaticQuery
    query={query}
    render={(data) =>
      square ? (
        <SquareLogo fluid={data.square.childImageSharp.fluid} alt="Logo" />
      ) : (
        <WideLogo fluid={data.wide.childImageSharp.fluid} alt="Logo" />
      )
    }
  />
);

SiteLogo.defaultProps = {
  square: false,
};

const SquareLogo = styled(Img)({
  maxWidth: 148,
});

const WideLogo = styled(Img)({
  maxWidth: 350,
});

export default SiteLogo;
