import React from 'react';
import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const query = graphql`
  query {
    left: file(relativePath: { eq: "HorizontalLine/dot-left.png" }) {
      childImageSharp {
        fluid(maxWidth: 34) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    right: file(relativePath: { eq: "HorizontalLine/dot-right.png" }) {
      childImageSharp {
        fluid(maxWidth: 34) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    middle: file(relativePath: { eq: "HorizontalLine/dot-middle.png" }) {
      publicURL
    }
  }
`;

const Dot = () => (
  <StaticQuery
    query={query}
    render={(data) => (
      <RuledLine>
        <DotOrnament fluid={data.left.childImageSharp.fluid} alt="RuledLineLeft" />
        <DotMiddle url={data.middle.publicURL} />
        <DotOrnament fluid={data.right.childImageSharp.fluid} alt="RuledLineRight" />
      </RuledLine>
    )}
  />
);

const RuledLine = styled.div({
  display: 'flex',
});

const DotOrnament = styled(Img)({
  width: 34,
});

const DotMiddle = styled.div((props) => ({
  backgroundImage: `url(${props.url})`,
  backgroundPosition: 'center center',
  backgroundRepeat: 'repeat-x',
  backgroundSize: '4px 2px',
  flexGrow: 1,
}));

export default Dot;
