// @flow strict

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const UPDATE_WINDOW_DIMENSION = 'UPDATE_WINDOW_DIMENSION';

export const toggleDrawer = () => ({
  type: TOGGLE_DRAWER,
});

export const updateWindowDimension = (width: Number, height: Number) => ({
  type: UPDATE_WINDOW_DIMENSION,
  payload: {
    width,
    height,
  },
});

export type Actions =
  | ReturnType<typeof toggleDrawer>
  | ReturnType<typeof updateWindowDimension>;
