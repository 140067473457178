import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import Colophon from '../atoms/Colophon';
import HorizontalLine from '../atoms/HorizontalLine';
import SiteLogo from '../atoms/SiteLogo';
import HokenBanner from '../atoms/HokenBanner';

const pdfs = [
  { name: 'プライバシーポリシー', filename: 'privacy-policy.pdf' },
  { name: '勧誘方針', filename: 'solicitation-policy.pdf' },
];

const SiteFooter = () => (
  <Root>
    <LogoLayout>
      <a href="https://www.anicom-sompo.co.jp/lp/" target="_blank" rel="noreferrer">
        <HokenBanner />
      </a>
    </LogoLayout>
    <MenuLayout>
      {pdfs.map((pdf) => (
        <MenuItem key={pdf.filaname}>
          <a href={pdf.filename} target="_blank" rel="noreferrer">
            {pdf.name}
          </a>
        </MenuItem>
      ))}
    </MenuLayout>
    <LogoLayout>
      <Link to="/">
        <SiteLogo />
      </Link>
    </LogoLayout>
    <HorizontalLine solid height="8px" />
    <ColophonLayout>
      <Colophon align="center" />
    </ColophonLayout>
  </Root>
);

const Root = styled.div({
  padding: '4rem 0 1rem',
});

const LogoLayout = styled.div((props) => ({
  margin: '0 auto 1rem',
  width: '60%',
  [props.theme.mediaQuery.minTablet]: {
    width: 350,
  },
}));

const MenuLayout = styled.ul({
  display: 'flex',
  padding: 0,
  margin: '0 0 1rem',
  listStyle: 'none',
  justifyContent: 'center',
});

const MenuItem = styled.li({
  fontSize: 14,
  '&:first-child': {
    marginRight: '1rem',
  },
  '&:last-child': {
    marginLeft: '1rem',
  },
});

const ColophonLayout = styled.div({
  marginTop: '1rem',
});

export default SiteFooter;
