import React from 'react';
import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const query = graphql`
  query {
    file(relativePath: { eq: "HorizontalLine/crown.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Crown = () => (
  <StaticQuery
    query={query}
    render={(data) => <RuledLine fluid={data.file.childImageSharp.fluid} alt="RuledLine" />}
  />
);

const RuledLine = styled(Img)({
  maxWidth: 450,
});

export default Crown;
