import breakpoints from './breakpoints';

const theme = {
  breakpoints,
  colors: {
    primary: '#EE7B68',
    secondary: '#ECA740',
    background: '#FFF',
    text: '#7D4E23',
  },
  layout: {
    maxWidth: 1060,
  },
  mediaQuery: {
    minTablet: `@media (min-width: ${breakpoints.smartphone}px)`,
    minLaptop: `@media (min-width: ${breakpoints.tablet}px)`,
    minDesktop: `@media (min-width: ${breakpoints.laptou}px)`,
    maxSmartphone: `@media only screen and (max-width: ${breakpoints.tablet}px)`,
    maxTablet: `@media only screen and (max-width: ${breakpoints.laptop}px)`,
    maxLaptop: `@media only screen and (max-width: ${breakpoints.desktop}px)`,
  },
  text: {
    baseSize: 16,
    baseLineHeight: 1.7,
    bodyFontFamily: ['Noto Serif JP', 'serif'],
    bodyWeight: 400,
    headerFontFamily: ['Noto Serif JP', 'serif'],
    headerWeight: 700,
  },
};

export default theme;
