// @flow strict
import React from 'react';

import Layout from '../molecules/Layout';

type Props = {
  element: React.Node,
};

const wrapPageElement = ({ element }: Props) => <Layout>{element}</Layout>;

export default wrapPageElement;
