// @flow strict
import { createStore } from 'redux';
import { Actions, TOGGLE_DRAWER, UPDATE_WINDOW_DIMENSION } from './action';
import { getWindowDimensions } from '../utils';

type State = {
  showDrawer: Boolean,
  dimension: {
    width: Number,
    height: Number,
  },
};

const dimension = getWindowDimensions();

const initialState: State = {
  showDrawer: false,
  dimension: {
    width: dimension.width,
    height: dimension.height,
  },
};

const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case TOGGLE_DRAWER: {
      return {
        ...state,
        showDrawer: !state.showDrawer,
      };
    }
    case UPDATE_WINDOW_DIMENSION: {
      return {
        ...state,
        dimension: { width: action.payload.width, height: action.payload.height },
      };
    }
    default:
      return state;
  }
};

export default createStore(reducer);
