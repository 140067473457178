const getWindowDimensions = () => {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  }

  const w = 375;
  const h = 812;
  return { w, h };
};

export default getWindowDimensions;
